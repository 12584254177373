import { Dialog, DialogContent } from "@mui/material";
import { usePreventScroll } from "@react-aria/overlays";
import { getKey } from "core/model/utils/strings";
import type { BarthelAssessment, DeepNullableObj } from "core/types";
import DatePickerInputField from "ds_legacy/components/DatePickerInputField";
import RadioGroupV2 from "ds_legacy/components/RadioGroupV2";
import {
  GREY_100,
  PRIMARY_DARK_COLOR,
  PRIMARY_LIGHT_COLOR,
  WHITE,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import {
  Z_INDEX_ASSESSMENT_CONTENT,
  border,
  dp,
  important,
  margin,
  padding,
} from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_FAMILY,
  FONT_SIZE_11,
  FONT_SIZE_14,
  FONT_SIZE_16,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_MEDIUM,
  FONT_WEIGHT_REGULAR,
  LINE_HEIGHT_18,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { AsteriskExplained } from "dsl/molecules/AsteriskExplained";
import { CSSProperties, useCallback, useMemo } from "react";
import {
  SimpleFormRenderProps,
  convertModelDefinition,
} from "react-forms-state";
import { ObservableValue } from "react-forms-state/src/Context";
import { Observable } from "rxjs";
import styled from "styled-components";
import { useTranslations } from "translations";
import { validationValueDef } from "../formDefinition";
import {
  AssessmentActions,
  DialogHeader,
  TotalScore,
  calculateScore,
  useGetFormValues,
} from "../shared";

const SCORE_BG_COLOR = PRIMARY_DARK_COLOR;
const LEFT_BG_COLOR = GREY_100;
const RIGHT_BG_COLOR = PRIMARY_LIGHT_COLOR;

const defaultValues: DeepNullableObj<Omit<BarthelAssessment, "score">> = {
  date: null,
  form: {
    bathing: null,
    dressing_and_undressing: null,
    fecal_incontinence: null,
    food: null,
    getting_up_and_walking: null,
    personal_care: null,
    setting_up_and_relocating: null,
    stair_climbing: null,
    toilet_use: null,
    urinary_incontinence: null,
  },
};

const readableValuesStyle: CSSProperties = {
  fontSize: FONT_SIZE_14,
  fontWeight: FONT_WEIGHT_REGULAR,
  lineHeight: FONT_SIZE_16,
};

const StyledRadioWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  font-family: ${FONT_FAMILY};

  p.Mui-error {
    position: absolute;
    top: auto;
    bottom: ${dp(4)};
    left: 0;
    width: 100%;
    z-index: ${Z_INDEX_ASSESSMENT_CONTENT};
  }
`;

function useBarthelAssessmentConfig(
  initialValues: BarthelAssessment["form"] | undefined,
) {
  const translations = useTranslations();
  const config: {
    legend: string;
    name: keyof BarthelAssessment["form"];
    options: {
      description: string;
      value: number;
    }[];
    value: number | undefined;
  }[] = useMemo(
    () => [
      {
        name: "food",
        legend: translations.acp.barthelIndex.food,
        options: [
          {
            value: 10,
            description: translations.acp.barthelIndex.foodValueTen,
          },
          {
            value: 5,
            description: translations.acp.barthelIndex.foodValueFive,
          },
          {
            value: 0,
            description: translations.acp.barthelIndex.foodValueZero,
          },
        ],
        value: initialValues?.food,
      },
      {
        name: "setting_up_and_relocating",
        value: initialValues?.setting_up_and_relocating,
        legend: translations.acp.barthelIndex.relocating,
        options: [
          {
            value: 15,
            description: translations.acp.barthelIndex.relocatingValueFifteen,
          },
          {
            value: 10,
            description: translations.acp.barthelIndex.relocatingValueTen,
          },
          {
            value: 5,
            description: translations.acp.barthelIndex.relocatingFive,
          },
          {
            value: 0,
            description: translations.acp.barthelIndex.relocatingZero,
          },
        ],
      },
      {
        name: "personal_care",
        value: initialValues?.personal_care,
        legend: translations.acp.barthelIndex.personalCare,
        options: [
          {
            value: 5,
            description: translations.acp.barthelIndex.personalCareValueFive,
          },
          {
            value: 0,
            description: translations.acp.barthelIndex.valueZero,
          },
        ],
      },
      {
        name: "toilet_use",
        value: initialValues?.toilet_use,
        legend: translations.acp.barthelIndex.toiletUse,
        options: [
          {
            value: 10,
            description: translations.acp.barthelIndex.toiletUseValueTen,
          },
          {
            value: 5,
            description: translations.acp.barthelIndex.toiletUseValueFive,
          },
          {
            value: 0,
            description: translations.acp.barthelIndex.toiletUseValueZero,
          },
        ],
      },
      {
        name: "bathing",
        value: initialValues?.bathing,
        legend: translations.acp.barthelIndex.bathing,
        options: [
          {
            value: 5,
            description: translations.acp.barthelIndex.bathingValueFive,
          },
          {
            value: 0,
            description: translations.acp.barthelIndex.valueZero,
          },
        ],
      },
      {
        name: "getting_up_and_walking",
        value: initialValues?.getting_up_and_walking,
        legend: translations.acp.barthelIndex.deplacement,
        options: [
          {
            value: 15,
            description: translations.acp.barthelIndex.deplacementValueFifteen,
          },
          {
            value: 10,
            description: translations.acp.barthelIndex.deplacementValueTen,
          },
          {
            value: 5,
            description: translations.acp.barthelIndex.deplacementValueFive,
          },
          {
            value: 0,
            description: translations.acp.barthelIndex.valueZero,
          },
        ],
      },
      {
        name: "stair_climbing",
        value: initialValues?.stair_climbing,
        legend: translations.acp.barthelIndex.stairClimbing,
        options: [
          {
            value: 10,
            description: translations.acp.barthelIndex.stairClimbingValueTen,
          },
          {
            value: 5,
            description: translations.acp.barthelIndex.stairClimbingValueFive,
          },
          {
            value: 0,
            description: translations.acp.barthelIndex.valueZero,
          },
        ],
      },
      {
        name: "dressing_and_undressing",
        value: initialValues?.dressing_and_undressing,
        legend: translations.acp.barthelIndex.dressing,
        options: [
          {
            value: 10,
            description: translations.acp.barthelIndex.dressingValueTen,
          },
          {
            value: 5,
            description: translations.acp.barthelIndex.dressingValueFive,
          },
          {
            value: 0,
            description: translations.acp.barthelIndex.valueZero,
          },
        ],
      },
      {
        name: "fecal_incontinence",
        value: initialValues?.fecal_incontinence,
        legend: translations.acp.barthelIndex.fecalIncontinence,
        options: [
          {
            value: 10,
            description:
              translations.acp.barthelIndex.fecalIncontinenceValueTen,
          },
          {
            value: 5,
            description:
              translations.acp.barthelIndex.fecalIncontinenceValueFive,
          },
          {
            value: 0,
            description:
              translations.acp.barthelIndex.fecalIncontinenceValueZero,
          },
        ],
      },
      {
        name: "urinary_incontinence",
        value: initialValues?.urinary_incontinence,
        legend: translations.acp.barthelIndex.uninaryIncontinence,
        options: [
          {
            value: 10,
            description:
              translations.acp.barthelIndex.uninaryIncontinenceValueTen,
          },
          {
            value: 5,
            description:
              translations.acp.barthelIndex.uninaryIncontinenceValueFive,
          },
          {
            value: 0,
            description:
              translations.acp.barthelIndex.uninaryIncontinenceValueZero,
          },
        ],
      },
    ],
    [initialValues],
  );

  return config;
}

export function BarthelAssessmentContent({
  initialFormValue,
  isViewMode,
  onChange,
  valueChangeObs,
}: {
  initialFormValue?: BarthelAssessment;
  isViewMode?: boolean;
  onChange?: (value: any, statePath: string, validation: any) => void;
  valueChangeObs?: Observable<ObservableValue>;
}) {
  const isPrint = usePrint();
  const config = useBarthelAssessmentConfig(initialFormValue?.form);
  const translations = useTranslations();

  let formValue = useGetFormValues(valueChangeObs);
  if (isViewMode && initialFormValue) {
    formValue = initialFormValue.form;
  }

  return (
    <VerticalLayout width="100%" overflow="visible" gap={padding(2)}>
      <VerticalLayout width="100%" overflow="visible">
        {!isViewMode && (
          <Body
            style={{ margin: 0 }}
            fontSize={FONT_SIZE_14}
            fontWeight={FONT_WEIGHT_MEDIUM}
            lineHeight={LINE_HEIGHT_18}
          >
            {
              translations.patient.medicalDiagnosis.singerPatientProfile
                .subtitle
            }
          </Body>
        )}
        {!isPrint && <AsteriskExplained />}
      </VerticalLayout>
      <div>
        <DatePickerInputField
          disabled={isViewMode && !isPrint}
          disableFuture
          elementName="date"
          hasCustomValidation
          label={translations.patient.medicalDiagnosis.assessmentDate}
          onChange={(value) => onChange?.(value as any, "date", true)}
          placeholder={translations.patient.medicalDiagnosis.assessmentDate}
          required={!isPrint}
          value={isViewMode ? initialFormValue?.date : undefined}
        />
      </div>
      <StyledRadioWrapper>
        {config.map(({ legend, name, options, value }, i) => (
          <div
            key={getKey(legend, i)}
            style={{
              position: "relative",
              background: isPrint ? undefined : RIGHT_BG_COLOR,
              display: "flex",
              margin: margin(2, 0, 0, 0),
              width: "100%",
            }}
          >
            <RadioGroupV2
              elementName={`form.${name}`}
              radioGroupSx={{
                "@media print": {
                  pageBreakInside: "avoid",
                },
              }}
              formControlLabelSx={{
                padding: padding(1.25, 0, 1.25, 2),
                zIndex: Z_INDEX_ASSESSMENT_CONTENT + 1,
                borderBottom: border({ color: "transparent", width: 4 }),
                margin: margin(0),
              }}
              formControlSx={{
                background: isPrint ? undefined : LEFT_BG_COLOR,
                padding: isPrint ? padding(0) : padding(2, 0),
                position: "static",
                width: "100%",
              }}
              formLabelSx={{
                alignItems: "center",
                background: isPrint ? undefined : SCORE_BG_COLOR,
                borderTopLeftRadius: dp(8),
                borderTopRightRadius: dp(8),
                boxSizing: "border-box",
                color: isPrint ? undefined : important(WHITE),
                display: "flex",
                fontSize: FONT_SIZE_16,
                fontWeight: FONT_WEIGHT_BOLD,
                padding: padding(1, 1, 1, 2),
                width: "100%",
                "& .MuiFormLabel-asterisk": {
                  color: isPrint ? undefined : important(WHITE),
                },
              }}
              formHelperTextSx={{
                fontSize: FONT_SIZE_11,
                left: dp(19),
                position: "absolute",
                top: dp(24),
              }}
              key={name}
              label={legend}
              options={options.map((option) => ({
                ariaDescribedBy: `${name}_${option.value}_description`,
                id: option.value,
                label: option.value.toString(),
                value: option.value,
                checked:
                  isViewMode && typeof value === "number"
                    ? option.value === value
                    : undefined,
                disabled: isViewMode && !isPrint,
              }))}
              radioSx={{ background: WHITE, padding: 0 }}
              required
            />
            <div
              style={{
                background: isPrint ? undefined : RIGHT_BG_COLOR,
                padding: isPrint ? padding(0, 2) : padding(2),
                position: "absolute",
                top: dp(44),
                left: dp(90),
                width: `calc(100% - ${dp(122)})`,
                bottom: dp(0),
              }}
            >
              {options.map((option, i) => (
                <div
                  key={getKey(legend, i)}
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center",
                    pointerEvents: "none",
                    height: dp(44),
                    margin: i === 0 ? margin(0) : margin(0.5, 0, 0, 0),
                  }}
                >
                  <Body
                    as="p"
                    margin={margin(0)}
                    style={{ ...readableValuesStyle }}
                    id={`${name}_${option.value}_description`}
                  >
                    <span>{option.description}</span>
                  </Body>
                </div>
              ))}
            </div>
          </div>
        ))}
      </StyledRadioWrapper>
      <HorizontalLayout justify="flex-end">
        <TotalScore score={calculateScore(formValue)} />
      </HorizontalLayout>
    </VerticalLayout>
  );
}

export function BarthelAssessmentModal({
  initialValues = {},
  isOpen,
  onCancel,
  onSubmit,
}: {
  initialValues?: Partial<Omit<BarthelAssessment, "score">>;
  isOpen?: boolean;
  onCancel?: () => void;
  onSubmit?: (values: BarthelAssessment) => void;
}) {
  usePreventScroll({ isDisabled: !isOpen });
  const translations = useTranslations();
  const { isTablet } = useMedia();

  const handleSubmit = useCallback(
    (values: Omit<BarthelAssessment, "score">) => {
      onSubmit?.({ ...values, score: calculateScore(values.form) });
    },
    [onCancel],
  );

  return (
    <Dialog
      fullScreen={isTablet}
      maxWidth={false}
      onClose={onCancel}
      open={!!isOpen}
      PaperProps={{
        "aria-modal": "true",
        sx: { maxWidth: dp(1050), width: "100%" },
      }}
    >
      <DialogHeader
        title={translations.acp.barthelIndex.dialogTitle}
        onCancel={onCancel}
      />
      <SimpleFormRenderProps
        asHtmlForm
        onSubmit={handleSubmit}
        formStyle={{
          boxSizing: "border-box",
          display: "unset",
          padding: padding(0, 2, 2, 0),
        }}
        formInputValue={
          initialValues.form && initialValues.date
            ? initialValues
            : defaultValues
        }
        modelDefinition={convertModelDefinition({
          ...validationValueDef(
            "date",
            translations.patient.medicalDiagnosis.singerPatientProfile
              .creationDateError,
          ),
          ...validationValueDef("form.food"),
          ...validationValueDef("form.setting_up_and_relocating"),
          ...validationValueDef("form.personal_care"),
          ...validationValueDef("form.toilet_use"),
          ...validationValueDef("form.bathing"),
          ...validationValueDef("form.getting_up_and_walking"),
          ...validationValueDef("form.stair_climbing"),
          ...validationValueDef("form.dressing_and_undressing"),
          ...validationValueDef("form.fecal_incontinence"),
          ...validationValueDef("form.urinary_incontinence"),
        })}
      >
        {({ onChange, submit, valueChangeObs }) => {
          return (
            <>
              <DialogContent sx={{ overflowY: "hidden" }}>
                <BarthelAssessmentContent
                  onChange={onChange}
                  valueChangeObs={valueChangeObs}
                />
              </DialogContent>
              <AssessmentActions
                defaultValues={defaultValues}
                onCancel={onCancel}
                onChange={onChange}
                submit={submit}
              />
            </>
          );
        }}
      </SimpleFormRenderProps>
    </Dialog>
  );
}
